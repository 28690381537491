import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    tooglePlayPause(){
        const video = this.element.querySelector('video');
        const play_button = this.element.querySelector('.play-pause');
        if(video.paused){
            video.play();
            play_button.classList.add('hide');
            this.element.classList.add('playing');
        }else{
            video.pause();
            play_button.classList.remove('hide');
            this.element.classList.remove('playing');

        }
    }
}