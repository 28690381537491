import { Controller } from '@hotwired/stimulus';

import GLightbox from 'glightbox';
// import le css de glightbox
import 'glightbox/dist/css/glightbox.css';


export default class extends Controller {
    connect() {

        const lightbox = GLightbox({
            selector: '.slider-img-car',
            touchNavigation: true,
            loop: true,
            autoplayVideos: true,

        });

    }

    toggleActive(event) {
        const target = event.target;
        const article = target.parentNode;
        const ul = article.querySelector('ul');
        const ulHeight = ul.scrollHeight;
        const content = article.querySelector('.content-data');
        article.classList.toggle('active');
        if(article.classList.contains('active')) {
            content.style.height = ulHeight + 'px';
        } else {
            content.style.height = '0px';
        }
    }

    openPopUp(event){
        const target = event.target;
        const popUp = target.parentNode.querySelector('.pop-up');
        popUp.classList.add('open');
        // empêche le scroll de la page
        document.body.style.overflow = 'hidden';
    }
    closePopUp(event){
        const target = event.target;
        const popUp = target.parentElement.parentElement.parentElement;
        popUp.classList.remove('open');
        // permet le scroll de la page
        document.body.style = '';
    }

    changeImage (event) {
        const target = event.target;
        const imgContainer = this.element.querySelector('.img-principal');
        let Allimg = imgContainer.querySelectorAll('a');
        // ajoute la class hidden de toutes les images sauf celle qui a le même slide-id que l'image cliquée
        Allimg.forEach(img => {
            if(img.getAttribute('slide-id') === target.getAttribute('slide-id')){
                img.classList.remove('hidden');
            } else {
                img.classList.add('hidden');
            }
        });
    }

}