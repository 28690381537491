import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        console.log('reservation')


    }
        nextStep(){
        // affiche la prochaine étape
            const steps = this.element.querySelectorAll('.step');
            const currentStep = this.element.querySelector('.step.show');
            const nextStep = currentStep.nextElementSibling;
            currentStep.classList.remove('show');
            nextStep.classList.add('show');
            // scroll to id #reservation
            const reservation = this.element.querySelector('#container');
            reservation.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            // quand j'arrive à la dernière étape, le bouton redirige vers la page de confirmation
        }

        prevStep(){
            // affiche l'étape précédente
            const steps = this.element.querySelectorAll('.step');
            const currentStep = this.element.querySelector('.step.show');
            const prevStep = currentStep.previousElementSibling;
            currentStep.classList.remove('show');
            prevStep.classList.add('show');

            // scroll to id #reservation
            const reservation = this.element.querySelector('#container');
            reservation.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }

}