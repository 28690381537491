import { Controller } from '@hotwired/stimulus';
import { Navigation } from 'swiper/modules';

import Swiper from 'swiper';

import '../../../node_modules/swiper/swiper.css';

export default class extends Controller {
    constructor(element) {
        super(element);
        this.swiper = null;
        modules: [Navigation],
            this.optionsValue = {};
    }

    connect() {
        this.swiper = new Swiper(this.element, {
            ...this.defaultOptions,
            ...this.optionsValue,
            modules: [Navigation],
        });
    }

    disconnect() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
        }
    }

    get defaultOptions() {
        return {
            slidesPerView: 1.1, // Nombre de slides visibles
            spaceBetween: 0, // Espace entre les slides
            // centeredSlides: true, // Active le centrage des slides
            loop: true, // Active le mode loop
            loopAddBlankSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                360:{
                    slidesPerView: 1.2,
                },
                370: {
                    slidesPerView: 1.25,
                },
                500: {
                    slidesPerView: 1.5,
                },
                730: {
                    slidesPerView: 2,
                    centeredSlides: false,
                    spaceBetween: 0,
                },
                1150: {
                    slidesPerView: 3,
                    centeredSlides: true,
                },
                1500: {
                    slidesPerView: 4,
                    centeredSlides: false,
                }
            },
        };
    }
}