import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    openMenu() {
        const burger = this.element.querySelector('.burger-menu')
        const menu = this.element.querySelector('.header-menu')

        // scroll top when open menu on mobile and stop scroll
        if (burger.classList.contains('active')) {
            document.body.style.overflow = 'auto'
        } else {
            window.scrollTo(0, 0)
            document.body.style.overflow = 'hidden'
        }

        burger.classList.toggle('active')
        menu.classList.toggle('active')
    }

    // écoute la taille de l'écran pour fermer le menu si la taille est > 1280px
    closeMenuOnDesktopVersion() {
        const burger = this.element.querySelector('.burger-menu')
        const menu = this.element.querySelector('.header-menu')
        if (window.innerWidth > 1280) {
            burger.classList.remove('active')
            menu.classList.remove('active')
            document.body.style.overflow = 'auto'
        }
    }

    connect() {
        window.addEventListener('resize', this.closeMenuOnDesktopVersion.bind(this));
    }


}