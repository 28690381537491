import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    connect() {
    }

    showSellPart() {
    const iSellPart = this.element.querySelector('.sell-part');
    const iSearchPart = this.element.querySelector('.search-part');
        iSellPart.classList.add('active');
        iSearchPart.classList.remove('active');
    }
    showSearchPart() {
        const iSellPart = this.element.querySelector('.sell-part');
        const iSearchPart = this.element.querySelector('.search-part');
        iSearchPart.classList.add('active');
        iSellPart.classList.remove('active');
    }
}