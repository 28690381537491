import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    comparaisonItems = JSON.parse(localStorage.getItem('comparaisonItemsLikeAuto')) || []
    // comparaisonItems = []

    popUp = document.querySelector('#pop-up-comparaison')

    connect() {
        // ajoute la class active aux btn de comparaison si la voiture est déjà dans le local storage
        if (this.comparaisonItems != []){
            const popUpComparaison = this.element.querySelector('#pop-up-comparaison')
            popUpComparaison.classList.remove('hidden')
            popUpComparaison.classList.add('reduce')
        }
        this.updateComparaisonCards()
    }


    compareBtn(event) {
        const btnCompare = event.currentTarget
        const carCard = btnCompare.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
        let title = carCard.querySelector('.first-title').innerHTML
        let price = carCard.querySelector('.price-achat').innerHTML
        let image = carCard.querySelector('.img-container').querySelector('img').src
        const carId = carCard.getAttribute('data-id')
        const popUpComparaison = this.element.querySelector('#pop-up-comparaison')
        popUpComparaison.classList.remove('hidden')
        popUpComparaison.classList.remove('reduce')

        const informationCar = {
            // todo: en attendant de récupérer l'id de la voiture
            id: carId, title: title, price: price, image: image
        }

        if (!btnCompare.classList.contains('active')) {
            if (this.comparaisonItems.length >= 3) {
                return
            }
            btnCompare.classList.add('active')
            this.comparaisonItems.push(informationCar)

        } else if (btnCompare.classList.contains('active')) {
            btnCompare.classList.remove('active')
            // todo: enlève la voiture du tableau comparaisonItems en fonction de l'id de la voiture
            this.comparaisonItems = this.comparaisonItems.filter(comparaisonItem => comparaisonItem.id !== carId)
        }

        // active la popup
        if (this.comparaisonItems.length >= 1) {
            this.popUp.classList.remove('hidden')
            const popUpBottom = this.popUp.querySelector('.bottom-pop-up')
            if (this.comparaisonItems.length > 1) {
                const btn = document.createElement('button')
                btn.innerHTML = 'Je compare'
                btn.classList.add('btn-primary')
                //ajoute une source au btn
                btn.addEventListener('click', () => {
                    // todo: ici il faudra passer les voitures sur la page comparaison
                    //redirige ver la page comparaison
                    window.location.href = '/page-comparaisons.html'
                })
                // supprime les enfants de text et ajoute le btn
                popUpBottom.innerHTML = ''
                popUpBottom.appendChild(btn)
            } else {
                popUpBottom.innerHTML = ''
                popUpBottom.innerHTML = 'Sélectionnez encore un ou deux véhicules'

            }
        } else {
            this.popUp.classList.add('hidden')
        }

        // enregistre les voitures dans le local storage
        localStorage.setItem('comparaisonItemsLikeAuto', JSON.stringify(this.comparaisonItems))
        this.updateComparaisonCards()
    }

    updateComparaisonCards() {
        const comparaisonCards = document.querySelectorAll('.comparaison-card')
        const comparaisonItems = JSON.parse(localStorage.getItem('comparaisonItemsLikeAuto')) || []
        // for de 3
        for (let  i = 0; i < 3; i++) {
            const card = comparaisonCards[i]
            const car = comparaisonItems[i]
            const cardContent = card.querySelector('.content')
            cardContent.innerHTML = ''
            if (car) {
                card.classList.remove('empty')
                card.setAttribute('data-id', car.id)

                // Création de l'élément div avec img
                const div = document.createElement('div')
                const img = document.createElement('img')
                img.setAttribute('src', car.image)
                img.setAttribute('loading', 'lazy')
                div.appendChild(img)

                // Création de l'élément paragraphe avec la classe "model"
                const modelParagraph = document.createElement('p')
                modelParagraph.classList.add('model')
                modelParagraph.textContent = car.title

                // Création de l'élément paragraphe avec la classe "price"
                const priceParagraph = document.createElement('p')
                priceParagraph.classList.add('price')
                priceParagraph.textContent = car.price

                // Ajout de l'élément paragraphe (price) à l'élément div
                div.appendChild(priceParagraph)
                cardContent.appendChild(div)
                cardContent.appendChild(modelParagraph)
                cardContent.appendChild(priceParagraph)

            } else {
                card.classList.add('empty')
                card.setAttribute('data-id', '')
            }
        }

        if (this.comparaisonItems.length >= 1) {
            this.popUp.classList.remove('hidden')
            const popUpBottom = this.popUp.querySelector('.bottom-pop-up')
            if (this.comparaisonItems.length > 1) {
                const btn = document.createElement('button')
                btn.innerHTML = 'Je compare'
                btn.classList.add('btn-primary')
                //ajoute une source au btn
                btn.addEventListener('click', () => {
                    // todo: ici il faudra passer les voitures sur la page comparaison
                    //redirige ver la page comparaison
                    window.location.href = '/page-comparaisons.html'
                })
                // supprime les enfants de text et ajoute le btn
                popUpBottom.innerHTML = ''
                popUpBottom.appendChild(btn)
            } else {
                popUpBottom.innerHTML = ''
                popUpBottom.innerHTML = 'Sélectionnez encore un ou deux véhicules'

            }
        } else {
            this.popUp.classList.add('hidden')
            const allBtnComparaison = document.querySelectorAll('.compare-btn')
            allBtnComparaison.forEach(btn => {
                btn.classList.remove('active')
            })
        }

        const allCarsFromThePage = document.querySelectorAll('.container-car-card')
        allCarsFromThePage.forEach(carCard => {
            const carId = carCard.getAttribute('data-id')
            const btnCompare = carCard.querySelector('.compare-btn')
            const car = this.comparaisonItems.find(comparaisonItem => comparaisonItem.id === carId)
            if (car) {
                btnCompare.classList.add('active')
            }else{
                btnCompare.classList.remove('active')
            }
        })

    }

    deleteComparaisonCards(event){
        const cross = event.currentTarget;
        const card = cross.parentElement;
        if (card.classList.contains('empty')) return;
        const id = card.getAttribute('data-id');
        this.comparaisonItems = this.comparaisonItems.filter(comparaisonItem => comparaisonItem.id !== id)
        localStorage.setItem('comparaisonItemsLikeAuto', JSON.stringify(this.comparaisonItems));
        this.updateComparaisonCards()
    }

    closeComparaisonPopUp(){
        this.popUp.classList.add('reduce')
        // this.comparaisonItems = []
        // localStorage.setItem('comparaisonItemsLikeAuto', JSON.stringify(this.comparaisonItems))
        this.connect()
    }
    openComparaisonPopUp(){
        this.popUp.classList.remove('reduce')
        this.popUp.classList.remove('hidden')
    }
}