import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['offerValue']

    connect() {
        console.log('test')

    }

    nextStep() {
        // affiche la prochaine étape
        const currentStep = this.element.querySelector('.step.show')
        const nextStep = currentStep.nextElementSibling
        currentStep.classList.remove('show')
        nextStep.classList.add('show')

        // scroll to id #reservation
        const reservation = this.element.querySelector('#container')
        reservation.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }

    prevStep() {
        // affiche l'étape précédente
        const currentStep = this.element.querySelector('.step.show')
        const prevStep = currentStep.previousElementSibling
        currentStep.classList.remove('show')
        prevStep.classList.add('show')

        // scroll to id #reservation
        const reservation = this.element.querySelector('#container')
        reservation.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }

    offerValue() {
        const offer = this.offerValueTarget
        let offerValue = parseFloat(offer.value).toFixed(2)
        console.log(offerValue)
        // récupère le nombre de tentative restante dans les cookies si il n'y en a pas on met 3
        let tentative = 2
        let cookie = document.cookie.split(';')
        for (let i = 0; i < cookie.length; i++) {
            if (cookie[i].includes('tentative')) {
                tentative = parseInt(cookie[i].split('=')[1])
            }
        }
        if (isNaN(offerValue)) {
            offerValue = 0
        }

        if (offerValue < 30000) {
            if (tentative >= 1) {
                this.popUp(offerValue, tentative)
            } else {
                this.popUpTooManyTry()
            }

        } else {
            // redirige vers valid page offer
            window.location.href = '/page-valid-offre.html'
        }
    }

    popUp(offerValue, tentative) {
        let text = '<p class=\'text bold \'>Oups... Ton offre est en-dessous de notre seuil de négociation</p>'
        if(tentative != 2) {
            text = '<p class=\'text bold \'>Reoups... Ton offre est encore en-dessous de notre seuil de négociation</p>'
        }

        let popUp =
            '<div class=\'pop-up-offre\'>' +
            '<div class=\'pop-up-content\'>' +
            '<p class=\'text bold \'>tu as proposé <span class="text blue">' + offerValue + ' €</span></p>' +
            text +
            '<p class=\'text\'>Il te reste ' + tentative + ' tentative de négociation pour ce véhicule</p>' +
            '<button class=\'btn close-pop-up\'>Je négocie à nouveau</button>' +
            '</div>' +
            '</div>'
        this.element.insertAdjacentHTML('beforeend', popUp)
        const closePopUp = this.element.querySelector('.close-pop-up')
        closePopUp.addEventListener('click', () => {
            this.element.querySelector('.pop-up-offre').remove()

            let date = new Date()
            date.setTime(date.getTime() + (5 * 60 * 1000))
            document.cookie = 'tentative=' + (tentative - 1) + '; expires=' + date.toUTCString() + '; path=/'
        })
    }


    popUpTooManyTry() {
        let popUp =
            '<div class=\'pop-up-offre\'>' +
            '<div class=\'pop-up-content\'>' +
            '<p class=\'text bold \'>Désolé toutes tes propositions sont restées en-dessous de notre seuil de négociation</p>' +
            '<p class=\'text bold \'>Tu pourras retenter ta chance dans 24 heures</p>' +
            '<button class=\'btn detailRedirection\'>Revenir sur la page du véhicule</button>' +
            '</div>' +
            '</div>'
        this.element.insertAdjacentHTML('beforeend', popUp)
        // redirige vers page-detail-vehicule.html.html
        this.element.querySelector('.detailRedirection').addEventListener('click', () => {
            window.location.href = '/page-detail-vehicule.html'
        })
    }

}