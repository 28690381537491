import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    toggleActive() {
        this.element.querySelector('.compare-btn').classList.toggle('active')
    }

    showMore() {
        this.element.querySelector('.cars-card').classList.toggle('active')
    }

    addFavorite() {
        // evite le spam de click sur le bouton en mettant un delais de 1.5 secondes avant de pouvoir recliquer
        this.element.querySelector('.favorite-btn').disabled = true
        setTimeout(() => {
            this.element.querySelector('.favorite-btn').disabled = false
        }, 1500)

        this.element.querySelector('.favorite-btn').classList.toggle('active')

        if (this.element.querySelector('.favorite-btn').classList.contains('active')) {
            let number = ''
            let delay = 0;
            for (let i = 1; i <= 5; i++) {
                if (i % 2 === 0) {
                    number = 'one'
                } else {
                    number = 'two'
                }
                // random entre 1 et 50
                let left_position = Math.floor(Math.random() * 100) + 1;

                this.element.querySelector('.favorite-btn').insertAdjacentHTML(
                    'afterend',

                    '<div class="favoritePop ' + number + '" ' +
                    'style="animation-delay: '+ delay +'s; left: '+ left_position +'%"' +
                    '>' +
                    '<svg viewBox="0 0 27 24" xmlns="http://www.w3.org/2000/svg">\n' +
                    '    <path d="M13.7876 22.4887C13.6836 22.5699 13.5923 22.6406 13.515 22.7001C13.4377 22.6408 13.3463 22.5703 13.2422 22.4893C12.8762 22.2046 12.3523 21.7896 11.7233 21.2701C10.4645 20.2307 8.78806 18.7758 7.11315 17.1118C5.43615 15.4457 3.77248 13.5815 2.53082 11.7237C1.28324 9.85708 0.505126 8.06048 0.500022 6.51148C0.634903 3.19933 3.52577 0.500014 7.14108 0.500014C9.72801 0.500014 11.9603 1.90318 13.0611 3.92124L13.5014 4.72864L13.9396 3.92002C15.0325 1.90306 17.2564 0.500014 19.8511 0.500015L19.8521 0.500013C23.4685 0.493121 26.3655 3.18652 26.5 6.49C26.4986 8.03709 25.7243 9.83409 24.4802 11.7027C23.2421 13.5621 21.5818 15.4293 19.9077 17.0986C18.2357 18.7658 16.5616 20.2241 15.3046 21.2663C14.6764 21.787 14.1532 22.2031 13.7876 22.4887Z"\n' +
                    '          stroke="white" />\n' +
                    '</svg>' +
                    '</div>'
                )
                    // ajoute 0.2s d'animation delay à chaque loop
                    delay += 0.2;
                setTimeout(() => {
                    this.element.querySelector('.favoritePop').remove()
                }, 2000)
            }
        }
    }
}