import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {

        if (window.innerWidth >= 1280) {
            this.openFilterContainer()
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth > 1280) {
                this.openFilterContainer()
            }
        })
    }

    openFilterContainer() {
        const filtersPart = this.element
        const filters = this.element.querySelector('.filters')
        if (!filtersPart.classList.contains('open')) {
            filtersPart.classList.add('open')
            filters.classList.add('open')
        }
    }

    closeFilterContainer() {
        const filtersPart = this.element
        const filters = this.element.querySelector('.filters')
        if (filtersPart.classList.contains('open')) {
            filtersPart.classList.remove('open')
            filters.classList.remove('open')
        }
    }

    openPopUpFilter(event) {
        const filterContent = event.currentTarget
        const popUp = filterContent.parentNode.querySelector('.pop-up-filtre')
        popUp.classList.add('open')
        // empêche le scroll de la page
        document.body.style.overflow = 'hidden'
    }

    closePopUpFilter(event) {
        const filterContent = event.currentTarget
        const popup = filterContent.parentNode.parentNode.parentNode.parentNode
        popup.classList.remove('open')
        // permet le scroll de la page
        document.body.style = '';
    }

    toggleChoicePopUp(event) {
        const choice = event.currentTarget
        const filtre = choice.parentElement.parentElement.parentElement.parentElement.parentElement
        choice.classList.toggle('selected')
    }

    validFilterChoice(event) {
        const validButton = event.currentTarget
        const popUp = validButton.parentElement.parentElement.parentElement.parentElement
        const selectedFilters = popUp.querySelectorAll('.selected')
        const filter = popUp.parentElement
        const showSelectedFilter = filter.querySelector('.selected-filter')
        let selectedFiltersText = Array.from(selectedFilters).map(filter => filter.textContent).join(' / ')
        let inputMin = ''
        let inputMax = ''
        if (popUp.querySelector('.min') && popUp.querySelector('.max')) {

            inputMin = popUp.querySelector('.min').value.trim() === '' ? '' : parseInt(popUp.querySelector('.min').value)
            inputMax = popUp.querySelector('.max').value.trim() === '' ? '' : parseInt(popUp.querySelector('.max').value)

            if (isNaN(inputMin) || isNaN(inputMax)) {
                // Gérer le cas où une des valeurs n'est pas valide
                alert('Veuillez saisir des nombres entiers valides pour les prix')
                return
            } else if ((inputMax !== '' && inputMin !== '') && inputMin > inputMax) {
                // Vérification que la deuxième valeur n'est pas inférieure à la première
                alert('La deuxième valeur ne doit pas être inférieure à la première.')
                return
            } else {
                // si l'un des deux inputs sont vides
                if (inputMin !== '' || inputMax !== '') {
                    selectedFiltersText = inputMin + ' - ' + inputMax
                }
            }
        }

        showSelectedFilter.textContent = selectedFiltersText
        if (selectedFilters.length > 0 || inputMin !== '' || inputMax !== '') {
            filter.classList.add('active')
        } else {
            filter.classList.remove('active')
        }

        popUp.classList.remove('open')
        document.body.style = '';

    }
}