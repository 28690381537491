import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
    }

    nextStep() {

        // affiche la prochaine étape
        const currentStep = this.element.querySelector('.step.show')
        const nextStep = currentStep.nextElementSibling
        currentStep.classList.remove('show')
        nextStep.classList.add('show')

        const containerStepMenu = this.element.querySelector('.container-step-info')
        if (nextStep.classList.contains('car-info-part')) {
            containerStepMenu.querySelector('.first-step').classList.add('active')
            containerStepMenu.querySelector('.second-step').classList.remove('active')
            containerStepMenu.querySelector('.third-step').classList.remove('active')
        } else if (nextStep.classList.contains('user-part')) {
            containerStepMenu.querySelector('.first-step').classList.add('active')
            containerStepMenu.querySelector('.second-step').classList.add('active')
            containerStepMenu.querySelector('.third-step').classList.remove('active')
        } else if (nextStep.classList.contains('estimation-part')) {
            containerStepMenu.querySelector('.first-step').classList.add('active')
            containerStepMenu.querySelector('.second-step').classList.add('active')
            containerStepMenu.querySelector('.third-step').classList.add('active')
        }
    }

    prevStep() {
        // affiche l'étape précédente
        const currentStep = this.element.querySelector('.step.show')
        const prevStep = currentStep.previousElementSibling
        currentStep.classList.remove('show')
        prevStep.classList.add('show')

        const containerStepMenu = this.element.querySelector('.container-step-info')
        if (prevStep.classList.contains('car-info-part')) {
            containerStepMenu.querySelector('.first-step').classList.add('active')
            containerStepMenu.querySelector('.second-step').classList.remove('active')
            containerStepMenu.querySelector('.third-step').classList.remove('active')
        } else if (prevStep.classList.contains('user-part')) {
            containerStepMenu.querySelector('.first-step').classList.add('active')
            containerStepMenu.querySelector('.second-step').classList.add('active')
            containerStepMenu.querySelector('.third-step').classList.remove('active')
        } else if (prevStep.classList.contains('estimation-part')) {
            containerStepMenu.querySelector('.first-step').classList.add('active')
            containerStepMenu.querySelector('.second-step').classList.add('active')
            containerStepMenu.querySelector('.third-step').classList.add('active')
        }
    }
}