import { Controller } from '@hotwired/stimulus';
import { Navigation } from 'swiper/modules';

import Swiper from 'swiper';

import '../../../node_modules/swiper/swiper.css';

export default class extends Controller {
    constructor(element) {
        super(element);
        this.swiper = null;
        modules: [Navigation],
            this.optionsValue = {};
    }

    connect() {
        this.swiper = new Swiper(this.element, {
            ...this.defaultOptions,
            ...this.optionsValue,
            modules: [Navigation],
        });
    }

    disconnect() {
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
        }
    }

    get defaultOptions() {
        return {
            slidesPerView: 4, // Nombre de slides visibles
            spaceBetween: 10, // Espace entre les slides
            loopAddBlankSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        };
    }
}