import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        const articles = this.element.querySelectorAll('article')
        const title_article = this.element.querySelectorAll('.clickable-part')
        let is_opened = false;
        title_article.forEach(art => {
            art.addEventListener('click', () => {
                // retire open de la classe .titlePart
                this.element.querySelector('.title-part').classList.remove('open')

                title_article.forEach(art => {
                    if (art.parentElement.classList.contains('open')) {
                        if (art !== art.parentElement) {
                            art.parentElement.classList.remove('open')
                        }
                    }
                })
                art.parentElement.classList.add('open')
            })
        })
        if (window.innerWidth >= 1280)
        {
            this.element.querySelector('.title-part').classList.remove('open')
            articles.forEach(article => {
                if (article.classList.contains('open'))
                {
                    is_opened = true;
                }
            })
            if (!is_opened)
            {
                articles[0].classList.add('open')
            }
        }
        // si windows width < 1280px enlève la classe open de .titlePart avec un écouteur de la taille de l'écran
        window.addEventListener('resize', () => {
            if (window.innerWidth >= 1280)
            {
                this.element.querySelector('.title-part').classList.remove('open')
                articles.forEach(article => {
                    if (article.classList.contains('open'))
                    {
                        is_opened = true;
                    }
                })
                if (!is_opened)
                {
                    articles[0].classList.add('open')
                }
            }

        })
    }
}