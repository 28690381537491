import { Application } from '@hotwired/stimulus'

// Stimulus controllers
import Hello from './controllers/hello_controller'
import CardCar from './controllers/components/car_card_controller'
import SwiperNosLikeDuMoment from './controllers/nos_likes_slider_controller'
import Header from './controllers/components/header_controller'
import Avantages from './controllers/components/avantages_controller'
import StartSection from './controllers/components/start_section_controller'
import Video from './controllers/components/video_controller'
import FilterOccasions from './controllers/components/filter_occasions_controller'
import Comparaison from './controllers/components/comparaisons_controller'
import SingleCar from './controllers/components/single_car_controller'
import SwiperThumbnailCar from './controllers/detail_car_thumbnail_slider_controller'
import Reservation from './controllers/components/reservation_controller'
import Offre from './controllers/components/offre_controller'
import Estimation from './controllers/components/estimation_controller'

// SCSS
import './../scss/app.scss'

// Start Stimulus
const application = Application.start()

// Declaration Stimulus controllers
application.register('hello', Hello)
application.register('components--car_card', CardCar)
application.register('nos_likes_slider_controller', SwiperNosLikeDuMoment)
application.register('components--header', Header)
application.register('components--avantages', Avantages)
application.register('components--start_section', StartSection)
application.register('components--video', Video)
application.register('components--filter_occasions', FilterOccasions)
application.register('components--comparaisons', Comparaison)
application.register('components--single_car', SingleCar)
application.register('detail_car_thumbnail_slider_controller', SwiperThumbnailCar)
application.register('components--reservation', Reservation)
application.register('components--offre', Offre)
application.register('components--estimation', Estimation)